// ProductList.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Constant from "../../constant/Constant";
import { Loader } from "../../component/Loader";
import { useAuth } from "../../hooks/useAuth";
const ProductList = () => {
  const navigate = useNavigate();
  const { user, login } = useAuth();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [imgSeemore, setWidth] = useState({ id: "", width: "10px" });
  const [addressData, setAddressData] = useState([]);

  console.log("addressData", user.role);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Constant.baseUrl}products`);
        const result = await response.json();
        setData(result.result);
        setImageUrl(result.imageURL);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    const fetchDataForSeller = async () => {
      try {
        const response = await fetch(`${Constant.baseUrl}sellerProduct/${user._id}`);
        const result = await response.json();

        const address = await fetch(`${Constant.baseUrl}addressBySellerId/${user._id}`);
        const addressresult = await address.json();
        setAddressData(addressresult.result);
        setData(result.result);
        setImageUrl(result.imageURL);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    if(user.role == "seller"){
      fetchDataForSeller();
    }else{
      fetchData();
    }
    
  }, []); // Empty dependency array to run the effect only once when the component mounts

  const handleSeemore = (item) => {
    setWidth({
      id: item,
      width: "100px",
    });
  };

  const List = () => {
    return (
      <div>
        <div className="Row space-between">
          <h2>Product</h2>
          <button onClick={() => navigate("add")}>Add product</button>
        </div>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Name</th>
              <th>Image</th>
              <th>Type</th>
              <th>SuitableLocation</th>
              <th>MSP</th>
              <th>Selling Price</th>
              <th>Special Price</th>
              <th>Discount</th>
              <th>Quantity</th>
              <th>Container Type</th>
              <th>Sellar Name</th>
              <th>Sellar Mno</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={row._id}>
                <td>{index + 1}</td>

                <td>{row.productName}</td>
                <td>
                  {row.images.map((img, index) => (
                    <img
                      key={img._id}
                      src={imageUrl + img?.url}
                      alt="catImage"
                      style={{
                        width:
                          imgSeemore.id == row._id ? imgSeemore.width : "20px",
                      }}
                    />
                  ))}
                  <button onClick={() => handleSeemore(row._id)}>
                    Zoom image
                  </button>
                </td>
                <td>{row.type}</td>

                <td>{row.suitableLocation}</td>
                <td>₹ {row.msp}</td>
                <td>₹ {row.sellingPrice}</td>
                <td>₹ {row.specialPrice}</td>
                <td>₹ {row.discount}</td>
                <td>{row.quantityInStock}</td>
                <td>{row.containerType}</td>

                <td>{row.sellerAddress.fullName}</td>
                <td>{row.sellerAddress.phoneNo}</td>
                <td>
                  <div className="Column">
                    <button onClick={() => navigate("update", { state: row })}>
                      Update
                    </button>
                    <button>Remove</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <Loader loading={isLoading} />
      {user.role != "seller" ? (
        List()
      ) : user.role == "seller" && addressData.length == 0 ? (
        <>
          <h2>
            You have to create shop address then you can add your product{" "}
          </h2>

          <h2>Add address before adding product</h2>

          <button onClick={() => navigate("/seller/AddAddress")}>
            Add address
          </button>
        </>
      ) : (
        List()
      )}
    </>
  );
};

export default ProductList;

// Profile.js
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import Constant from "../../constant/Constant";
import { Loader } from "../../component/Loader";

const Profile = () => {
  const { user, login } = useAuth();

  const [loggedUser, setUser] = useState(user);
  const [passwoedVal, sePassword] = useState({});

  const [openModal, setOpenModal] = useState('profile');
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateProfile = async (updatedUser) => {
    const data = new FormData();
    data.append("filename", loggedUser.image);
    data.append("userID", loggedUser.userID);
    data.append("email", loggedUser.email);
    data.append("firstName", loggedUser.firstName);
    data.append("lastName", loggedUser.lastName);
    data.append("shopName", loggedUser.shopName);
    data.append("phoneNo", loggedUser.phoneNo);
    // Send the FormData to your server for image upload
    try {
      const response = await fetch(
        `${Constant.baseUrl}updateSeller/${user?._id}`,
        {
          method: "POST",
          body: data,
        }
      );
      const userRes = await response.json();
      if (response.ok) {
        login(userRes.result);
        toast.success("Profile Updated!");
        setIsLoading(false);
      } else {
        toast.info("Profile Update failed!");

        setIsLoading(false);

        // Handle failure, such as displaying an error message
      }
    } catch (error) {
      toast.error("Somthing went wrong!");
      setIsLoading(false);
      console.error("Error uploading image:", error);
    }
  };

  const handleChangePassword = async (updatedUser) => {
    console.log('passwoedVal',passwoedVal)
    if(passwoedVal.newPassword!==passwoedVal.confirmPassword){
      toast.error("Password and confirm password does not match!");
      return
    }
    // const data = new FormData();
    // data.append("_id", user._id);
    // data.append("oldPassword", passwoedVal.oldPassword);
    // data.append("newPassword", passwoedVal.newPassword);
    // data.append("confirmPassword", passwoedVal.newPassword);

    let data={
      _id:user._id,
      oldPassword:passwoedVal.oldPassword,
      newPassword:passwoedVal.newPassword,
      confirmPassword:passwoedVal.confirmPassword,
    }
    // Send the FormData to your server for image upload
    try {
      const response = await fetch(
        `${Constant.baseUrl}changePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(data),
        }
      );
      const userRes = await response.json();
      console.log(userRes.code)
      if (response.ok) {
        sePassword({
          oldPassword:'',
          newPassword:'',
          confirmPassword:'',
        })
        toast.success("Password Updated!");
        setIsLoading(false);
      } else {
        sePassword({
          oldPassword:'',
          newPassword:'',
          confirmPassword:'',
        })
         toast.info(userRes.message);
        setIsLoading(false);

        // Handle failure, such as displaying an error message
      }
    } catch (error) {
      toast.error("Somthing went wrong!");
      setIsLoading(false);
      console.error("Error uploading image:", error);
    }
  };


  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "image") {
      const selectedImage = files[0];
      setUser({ ...loggedUser, [name]: selectedImage });

      // Display the selected image as a preview
      if (selectedImage) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      } else {
        setPreviewImage(null);
      }
    }
  };

  return (
    <div className="profile-container" style={{ maxWidth: "500px" }}>
      <Loader loading={isLoading} />

      <div className="profile-header">
        <h2>{loggedUser.firstName}'s Profile</h2>
      </div>
      <div className="profile-body">
        {openModal==='profile' && (
          <div className="profile-info">
            <div className="Row space-between">
              <h3>Profile Information</h3>
              <button type="button" onClick={() => setOpenModal('changepassword')}>
                Change Password
              </button>
              <button type="button" onClick={() => setOpenModal('updateProfile')}>
                Update Profile
              </button>
            </div>
            <img
              src={Constant.imageUrl + loggedUser.image}
              alt="catImage"
              className="img"
            />
            <p>
              <strong>Username:</strong> {loggedUser.userID}
            </p>
            <p>
              <strong>Phone:</strong> {loggedUser.phoneNo}
            </p>
            <p>
              <strong>Email:</strong> {loggedUser.email}
            </p>
            <p>
              <strong>First Name:</strong> {loggedUser.firstName}
            </p>
            <p>
              <strong>Last Name:</strong> {loggedUser.lastName}
            </p>
            <p>
              <strong>Shop Name:</strong> {loggedUser.shopName}
            </p>
            <p>
              <strong>Shop Name:</strong> {loggedUser.shopName}
            </p>
          </div>
        )}

        {openModal==='updateProfile' && (
          <div className="profile-update">
            <div className="Row space-between">
              <h3>Update Information</h3>
              <button type="button" onClick={() => setOpenModal('profile')}>
                View Profile
              </button>
            </div>
            <form>
              <label htmlFor="image">Choose Image:</label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleChange}
              />

              {previewImage && (
                <div className="image-preview">
                  <p>Selected Image Preview:</p>
                  <img
                    src={previewImage}
                    alt="Preview"
                    width={"100px"}
                    height={"100px"}
                  />
                </div>
              )}
              <label>
                Email:
                <input
                  type="text"
                  value={loggedUser.email}
                  onChange={(e) =>
                    setUser({ ...loggedUser, email: e.target.value })
                  }
                />
              </label>
              <label>
                Phone:
                <input
                  type="text"
                  value={loggedUser.phoneNo}
                  onChange={(e) =>
                    setUser({ ...loggedUser, phoneNo: e.target.value })
                  }
                />
              </label>
              <label>
                First Name:
                <input
                  type="text"
                  value={loggedUser.firstName}
                  onChange={(e) =>
                    setUser({ ...loggedUser, firstName: e.target.value })
                  }
                />
              </label>
              <label>
                Last Name:
                <input
                  type="text"
                  value={loggedUser.lastName}
                  onChange={(e) =>
                    setUser({ ...loggedUser, lastName: e.target.value })
                  }
                />
              </label>
              <label>
                Shop Name:
                <input
                  type="text"
                  value={loggedUser.shopName}
                  onChange={(e) =>
                    setUser({ ...loggedUser, shopName: e.target.value })
                  }
                />
              </label>

              <button
                type="button"
                onClick={() => handleUpdateProfile(loggedUser)}
              >
                Update Profile
              </button>
            </form>
          </div>
        )}

{openModal==='changepassword' && (
          <div className="profile-update">
            <div className="Row space-between">
              <h3>Change Password</h3>
              <button type="button" onClick={() => setOpenModal('profile')}>
                View Profile
              </button>
            </div>
            <form>

              <label>
                Current Password:
                <input
                  type="text"
                  value={passwoedVal.oldPassword}
                  onChange={(e) =>
                    sePassword({ ...passwoedVal, oldPassword: e.target.value.trim() })
                  }
                />
              </label>
              <label>
                Password:
                <input
                  type="text"
                  value={passwoedVal.newPassword}
                  onChange={(e) =>
                    sePassword({ ...passwoedVal, newPassword: e.target.value.trim() })
                  }
                />
              </label>

              <label>
                Confirm Password:
                <input
                  type="text"
                  value={passwoedVal.confirmPassword}
                  onChange={(e) =>
                    sePassword({ ...passwoedVal, confirmPassword: e.target.value.trim() })
                  }
                />
              </label>
    

              <button
                type="button"
                onClick={() => handleChangePassword(loggedUser)}
              >
                Change Password
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;

// UpdateCoupon.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Constant from '../../constant/Constant';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';


const UpdateCoupon = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [formData, setFormData] = useState(location.state);

  console.log('formData', formData.isActive)


  const handleChange = (e) => {

    const { name, value } = e.target;

    console.log(name, value)
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const data = new FormData();
    // data.append('code', formData.code);
    // data.append('description', formData.description);
    // data.append('validFrom',formData.validFrom);
    // data.append('validTo', formData.validTo);
    // data.append('isActive', formData.isActive);
    // data.append('discountAmount', formData.discountAmount);

    const data = {
      code: formData.code,
      discountAmount: formData.discountAmount,
      description: formData.description,
      validFrom: formData.validFrom,
      validTo: formData.validTo,
      isActive: formData.isActive
    }

    console.log('data', data)
    try {
      const response = await fetch(`${Constant.baseUrl}updateCoupon/${formData._id}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data),

      });

      if (response.ok) {
        toast.success("Coupon Updated!")

        console.log('Coupon Added successfully.');
        navigate("/dashboard/coupon")
      } else {
        toast.info("Coupon Update Failed!")

        console.error('Image upload failed.');
        // Handle failure, such as displaying an error message
      }
    } catch (error) {
      toast.error("Somthing went wrong!")
      console.error('Error uploading image:', error);
    }
  };

  return (
    <>
      <div className="Row space-between">
        <h2>Add Coupon</h2>
        <button onClick={() => navigate("/dashboard/coupon")}>Back to coupon list</button>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>

        <label htmlFor="code">Coupon Code:</label>
        <input
          type="text"
          id="code"
          name="code"
          value={formData.code}
          onChange={handleChange}
        />

        <label htmlFor="discountAmount">Discount Amount:</label>
        <input
          type="text"
          id="discountAmount"
          name="discountAmount"
          value={formData.discountAmount}
          onChange={handleChange}
        />

        <label htmlFor="validFrom">Valid From:</label>
        <input
          type="date"
          id="validFrom"
          name="validFrom"
          value={moment(formData.validFrom).format("YYYY-MM-DD")}
          onChange={handleChange}
        />

        <label htmlFor="validTo">Valid To:</label>
        <input
          type="date"
          id="validTo"
          name="validTo"
          value={moment(formData.validTo).format("YYYY-MM-DD")}
          onChange={handleChange}
        />

        <label htmlFor="isActive">Is Active:</label>

        <div onChange={handleChange}>
          <input type="radio" defaultChecked={formData.isActive == true} value={true} name="isActive" id="isActive" /> Yes
          <input type="radio" defaultChecked={formData.isActive == false} value={false} name="isActive" id="isActive" /> No
        </div>


        <label htmlFor="description">Description:</label>
        <textarea
          rows="10"
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
        ></textarea>

        <button type="submit">Submit</button>
      </form>
    </>

  );
};

export default UpdateCoupon;

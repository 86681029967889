// Dashboard.js
import React from 'react';
// import './Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      {/* <header className="dashboard-header">
        <h1>Dashboard</h1>
        <button className="logout-button">Logout</button>
      </header> */}
      <main className="dashboard-content">
        {/* Add your dashboard content here */}
        <p>Welcome to the Dashboard!</p>
      </main>
    </div>
  );
};

export default Dashboard;


import React, { useState, useEffect } from 'react';
import Constant from '../../constant/Constant';
import { useNavigate } from "react-router-dom";
import { Loader }  from '../../component/Loader';
import { useAuth } from "../../hooks/useAuth";

const SellersAddress = (props) => {
    const { user, login } = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Constant.baseUrl}addressBySellerId/${user._id}`);
        const result = await response.json();
        // setImageUrl(result.imageURL)
        console.log('result.result',result.result)
         setData(result.result);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once when the component mounts

  return (
    <div>
      <Loader loading={isLoading} />
       <div className="Row space-between">
        <h2>Address</h2>
      { data.length==0&& <button onClick={() => navigate("/seller/AddAddress")}>Add address</button>}
                  
        </div>
     
      { data.length>0?
       <table className="styled-table">
          <thead>
            <tr>
              <th>Sr No</th>
              {/* <th>Image</th> */}
              <th>Full name</th>
              <th>Phone no</th>
              <th>Street</th>
              <th>House no building name</th>
              <th>Area road name colony name</th>
              <th>City</th>
              <th>State</th>
              <th>Zip code</th>
              <th>Full address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={row._id}>
                <td>{index + 1}</td>
                {/* <td>
                  <img src={imageUrl + row.image} alt="catImage" className='img' />
                </td> */}

                <td>{row.fullName}</td>
                <td>{row.phoneNo}</td>
                <td>{row.street}</td>
                <td>{row.houseNoBuildingName}</td>
                <td>{row.areaRoadNameColonyName}</td>
                <td>{row.city}</td>
                <td>{row.state}</td>
                <td>{row.zipCode}</td>
                <td>{row.fullAddress}</td>
                <td >
                  <div className="Column">
                    <button onClick={() => navigate("/seller/updateAddress", { state: row })}>Update</button>
                    <button>Remove</button>
                  </div>

                </td>
              </tr>
            ))}
          </tbody>
        </table>:
        <>
                <h2>Add address before adding product</h2>

                <button onClick={() => navigate("/seller/AddAddress")}>Add address</button>

        </>
        }
    </div>
  );
};

export default SellersAddress;


// AddCoupon.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Constant from '../../constant/Constant';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const AddCoupon = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data ={
      code:formData.code,
      discountAmount:formData.discountAmount,
      description:formData.description,
      validFrom:formData.validFrom,
      validTo:formData.validTo,
      isActive:formData.isActive
    }

    try {
      const response = await fetch(`${Constant.baseUrl}addCoupon`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data),
    
      });

      if (response.ok) {
        toast.success("Coupan Added!")

        console.log('Coupon Added successfully.');
        navigate("/dashboard/coupon")
      } else {
        toast.info("Coupon Failed To Add!")

        console.error('Image upload failed.');
        // Handle failure, such as displaying an error message
      }
    } catch (error) {
      toast.error("Somthing went wrong!")

      console.error('Error uploading image:', error);
    }
  };

  return (
    <>
      <div className="Row space-between">
        <h2>Add Coupon</h2>
        <button onClick={() => navigate("/dashboard/coupon")}>Back to coupon list</button>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>

        <label htmlFor="code">Coupon Code:</label>
        <input
          type="text"
          id="code"
          name="code"
          value={formData.code}
          onChange={handleChange}
        />

        <label htmlFor="discountAmount">Discount Amount:</label>
        <input
          type="text"
          id="discountAmount"
          name="discountAmount"
          value={formData.discountAmount}
          onChange={handleChange}
        />

<label htmlFor="validFrom">Valid From:</label>
        <input
          type="date"
          id="validFrom"
          name="validFrom"
          value={formData.validFrom}
          onChange={handleChange}
        />



<label htmlFor="validTo">Valid To:</label>
        <input
          type="date"
          id="validTo"
          name="validTo"
          value={formData.validTo}
          onChange={handleChange}
        />

<label htmlFor="isActive">Is Active:</label>
<label htmlFor="isActive">Yes:</label>

        <input
          type="radio"
          id="isActive"
          name="isActive"
          value={true}
          onChange={handleChange}
        />
        <label htmlFor="isActive">No:</label>

                <input
          type="radio"
          id="isActive"
          name="isActive"
          value={false}
          onChange={handleChange}
        />

        <label htmlFor="description">Description:</label>
        <textarea
          rows="10"
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
        ></textarea>

        <button type="submit">Submit</button>
      </form>
    </>

  );
};

export default AddCoupon;


import { ThreeCircles } from 'react-loader-spinner'

export const Loader = ({ loading }) => {


    return (
        <>
            {loading &&
                <div className="loader">
                    <div className="ch">
                        <ThreeCircles
                            visible={true}
                            height="50"
                            width="50"
                            color="#4fa94d"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </div>
            }
        </>

    );
};

// Add.js
import React, { useState, useEffect } from "react";
import Constant from "../../constant/Constant";
import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "../../component/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";

const Add = () => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const imageData = location.state;
  console.log("location", imageData);
  const [formData, setFormData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);

  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const [options, setOptions] = useState([]);
  const [sellerOptions, setSellerOptions] = useState([]);
  const [selectedCategory, setSelectedOption] = useState("");
  const [selectedSellerOption, setSelectedSellerOption] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [formSubmit, setFormSubmit] = useState(false);

  const handleSelectChange = (e) => {
    const { name, value, files } = e.target;
    console.log(selectedCategory);

    console.log(e.target);
    if (name === "categoryDropdown") {
      setSelectedOption(e.target.value);

      options.filter((item) => {
        if (item._id == e.target.value) {
          console.log("item?.name", item?.name);
          setFormData({ ...formData, type: item?.name });
        }
      });
    }

    if (name === "sellerDropdown") {
      setSelectedSellerOption(e.target.value);
    }
  };

  useEffect(() => {
    // Simulating an asynchronous API call to fetch options
    const fetchOptions = async () => {
      try {
        // Replace this with your actual API endpoint or data fetching logic
        const response = await fetch(`${Constant.baseUrl}categories`);
        const data = await response.json();
        const sellers = await fetch(`${Constant.baseUrl}sellers`);
        const sellerResult = await sellers.json();
        setSellerOptions(sellerResult.result); // Set the fetched options in the state
        setOptions(data.result); // Set the fetched options in the state
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
    if (user.role == "seller") {
      setSelectedSellerOption(user._id);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "image") {
      // console.log('images---->',files[0])
      // const selectedImage = files[0];
      // setFormData({ ...formData, [name]: selectedImage });
      // // Display the selected image as a preview
      // if (selectedImage) {
      //   const reader = new FileReader();
      //   reader.onloadend = () => {
      //     setPreviewImage(reader.result);
      //   };
      //   reader.readAsDataURL(selectedImage);
      // } else {
      //   setPreviewImage(null);
      // }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const checkFormDataIsValied = () => {
    if (!selectedCategory) {
      return false;
    }
    if (!formData.productName) {
      return false;
    }
    if (!formData.sellingPrice) {
      return false;
    }
    if (!formData.msp) {
      return false;
    }
    if (!formData.specialPrice) {
      return false;
    }
    if (!formData.suitableLocation) {
      return false;
    }
    if (!formData.quantityInStock) {
      return false;
    }
    if (!formData.containerType) {
      return false;
    }
    if (!formData.containerMaterial) {
      return false;
    }
    if (!formData.hybrid) {
      return false;
    }
    if (!formData.description) {
      return false;
    }

    return true;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmit(true);

    if (!checkFormDataIsValied()) {
      return;
    }
    setIsLoading(true);
    const data = new FormData();

    let img = imageData.map((item) => {
      data.append("image", item.file);
      console.log("item", item.file);
    });

    let color = imageData.map((item) => {
      return item.description;
    });

    data.append("categoryId", selectedCategory);
    data.append("sellerID", selectedSellerOption);
    data.append("productName", formData.productName);
    data.append("msp", formData.msp);
    data.append("specialPrice", formData.specialPrice);
    data.append("sellingPrice", formData.sellingPrice);
    data.append("suitableLocation", formData.suitableLocation);
    data.append("quantityInStock", formData.quantityInStock);
    data.append("containerType", formData.containerType);
    data.append("containerMaterial", formData.containerMaterial);
    data.append("hybrid", formData.hybrid);
    data.append("placeOfOrigin", formData.placeOfOrigin);
    data.append("brand", formData.brand);
    data.append(
      "plantingAndCareInstructions",
      formData.plantingAndCareInstructions
    );
    data.append("colors", color);
    data.append("description", formData.description);
    data.append("type", formData.type);

    // Send the FormData to your server for image upload
    try {
      const response = await fetch(`${Constant.baseUrl}addProduct`, {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        toast.success("Product Added!");
        setIsLoading(false);

        console.log("Image uploaded successfully.");
        navigate(-2);
      } else {
        toast.info("Product Not added!");
        setIsLoading(false);

        console.error("Image upload failed.");
        // Handle failure, such as displaying an error message
      }
    } catch (error) {
      toast.error("Somthing went wrong!");
      setIsLoading(false);

      console.error("Error uploading image:", error);
    }
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div className="Row space-between">
        <h2>Add Product</h2>
        <div>
          <button onClick={() => navigate(-1)}>Back to upload image</button>
          <button onClick={() => navigate(-2)}>Back to product list</button>
        </div>
      </div>

      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="Row space-between">
          <div>
            <label htmlFor="categoryDropdown">Select Category:</label>

            <select
              id="categoryDropdown"
              name="categoryDropdown"
              className="dropdown"
              value={selectedCategory}
              onChange={handleSelectChange}
            >
              <option value="" disabled>
                Select Category
              </option>
              {options.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.name}
                </option>
              ))}
            </select>
            {formSubmit && !selectedCategory && (
              <label className="inputError" htmlFor="categoryerror">
                Category is required!
              </label>
            )}
          </div>

          <div>
            <label htmlFor="sellerDropdown">Seller:</label>
            <select
              id="sellerDropdown"
              className="dropdown"
              name="sellerDropdown"
              disabled={user.role == "seller" ? true : false}
              value={selectedSellerOption}
              onChange={handleSelectChange}
            >
              <option value="" disabled>
                Select Seller
              </option>
              {sellerOptions.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.firstName} - {option.shopName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <label htmlFor="type">Type</label>
        <input
          type="text"
          id="type"
          name="type"
          disabled
          value={formData.type}
          onChange={handleChange}
        />
        {formSubmit && !selectedCategory && (
          <label className="inputError" htmlFor="categoryerror">
            Select Category!
          </label>
        )}

        <label htmlFor="productName">Name:</label>
        <input
          type="text"
          id="productName"
          name="productName"
          value={formData.productName}
          onChange={handleChange}
        />
        {formSubmit && !formData.productName && (
          <label className="inputError" htmlFor="perror">
            Product name is required!
          </label>
        )}

        <label htmlFor="sellingPrice">Selling Price:</label>
        <input
          type="text"
          id="sellingPrice"
          name="sellingPrice"
          value={formData.sellingPrice}
          onChange={handleChange}
        />
        {formSubmit && !formData.sellingPrice && (
          <label className="inputError" htmlFor="sellingerror">
            Selling price is required!
          </label>
        )}

        <label htmlFor="specialPrice">Special Price:</label>
        <input
          type="text"
          id="specialPrice"
          name="specialPrice"
          value={formData.specialPrice}
          onChange={handleChange}
        />
        {formSubmit && !formData.specialPrice && (
          <label className="inputError" htmlFor="Special-error">
            Special price is required!
          </label>
        )}

        <label htmlFor="scientific_name">msp:</label>
        <input
          type="text"
          id="msp"
          name="msp"
          value={formData.msp}
          onChange={handleChange}
        />
        {formSubmit && !formData.msp && (
          <label className="inputError" htmlFor="msp-error">
            Max selling price is required!
          </label>
        )}

        <label htmlFor="brand">Brand</label>
        <input
          type="text"
          id="brand"
          name="brand"
          value={formData.brand}
          onChange={handleChange}
        />

        <label htmlFor="placeOfOrigin">Place Of Origin</label>
        <input
          type="text"
          id="placeOfOrigin"
          name="placeOfOrigin"
          value={formData.placeOfOrigin}
          onChange={handleChange}
        />

        <label htmlFor="hybrid">Hybrid</label>
        <input
          type="text"
          id="hybrid"
          name="hybrid"
          value={formData.hybrid}
          onChange={handleChange}
        />
        {formSubmit && !formData.hybrid && (
          <label className="inputError" htmlFor="hybrid-error">
            Hybrid is required!
          </label>
        )}

        <label htmlFor="containerMaterial">Container Material</label>
        <input
          type="text"
          id="containerMaterial"
          name="containerMaterial"
          value={formData.containerMaterial}
          onChange={handleChange}
        />
        {formSubmit && !formData.containerMaterial && (
          <label className="inputError" htmlFor="containerMaterial-error">
            Container material is required!
          </label>
        )}

        <label htmlFor="containerType">Container Type</label>
        <input
          type="text"
          id="containerType"
          name="containerType"
          value={formData.containerType}
          onChange={handleChange}
        />
        {formSubmit && !formData.containerType && (
          <label className="inputError" htmlFor="containerType-error">
            Container type is required!
          </label>
        )}

        <label htmlFor="quantityInStock">Quantity InStock</label>
        <input
          type="text"
          id="quantityInStock"
          name="quantityInStock"
          value={formData.quantityInStock}
          onChange={handleChange}
        />
        {formSubmit && !formData.quantityInStock && (
          <label className="inputError" htmlFor="quantityInStock-error">
            Quantity in stock is required!
          </label>
        )}

        <label htmlFor="suitableLocation">Suitable Location</label>
        <input
          type="text"
          id="suitableLocation"
          name="suitableLocation"
          value={formData.suitableLocation}
          onChange={handleChange}
        />
        {formSubmit && !formData.suitableLocation && (
          <label className="inputError" htmlFor="suitableLocation-error">
            Suitable location is required!
          </label>
        )}

        <label htmlFor="plantingAndCareInstructions">
          Planting And Care Instructions
        </label>
        <textarea
          rows="10"
          type="text"
          id="plantingAndCareInstructions"
          name="plantingAndCareInstructions"
          value={formData.plantingAndCareInstructions}
          onChange={handleChange}
        ></textarea>

        <label htmlFor="description">Description:</label>
        <textarea
          rows="10"
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
        ></textarea>
        {formSubmit && !formData.description && (
          <label className="inputError" htmlFor="description-error">
            Description is required!
          </label>
        )}

        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default Add;

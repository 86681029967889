// Login.js
import React, { useState,useEffect } from 'react';
import { useAuth } from "../../hooks/useAuth";
import Constant from '../../constant/Constant';
import { Loader }  from '../../component/Loader';
 import { ToastContainer, toast } from 'react-toastify';
 import { useNavigate } from "react-router-dom";

const Login = () => {
    const [loading, setLoading] = useState(null);
    const { login } = useAuth();
    const navigate = useNavigate();
   
   

   
   
    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const payload = {
            userID: data.get("email").trim(),
            password: data.get("password").trim()
        }

        try {
            const response = await fetch(`${Constant.baseUrl}auth`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                //    credentials: "include",

                body: JSON.stringify(payload),

            });

            const result = await response.json();
           
            console.log('result ----->');
            if (response.ok) {
                setLoading(false)
                toast.success("Login sucess!")
                console.log('Login sucess');
                login(result.data);

                //   navigate("/dashboard/coupon")
            } else {
                setLoading(false)
                toast.info("Login Failed!")
                console.error('Login Failed');
                // Handle failure, such as displaying an error message
            }
        } catch (error) {
            toast.error("Somthing went wrong!")
            setLoading(false)
            console.error('Error Login:', error);
        }

    }

    

    return (
        <div className="login-container">
      <Loader loading={loading}/>
      {/* <ToastContainer /> */}
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username:</label>
                <input
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus />

                <label htmlFor="password">Password:</label>
                <input
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password" />

                <button type="submit">Log In</button>
            </form>

            <div className="Row forgot-password space-between">
        <span onClick={()=>{navigate('forgotPassword')}}>Forgot password?</span>
        <span onClick={()=>{navigate('createAccount')}}>Create seller account?</span>
      </div>
        </div>
    );
};

export default Login;

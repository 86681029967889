import React, { useState, useEffect } from "react";
import Constant from "../../constant/Constant";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../component/Loader";
import { ToastContainer, toast } from "react-toastify";

const ImageUploadForm = () => {
  const navigate = useNavigate();
  const [imageData, setImageData] = useState([]);
  const [newImage, setNewImage] = useState({
    file: null,
    description: "",
  });
  const [imageURLS, setImageURLs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (imageData.length < 1) return;
    const newImageUrls = [];
    imageData.forEach((image) =>
      image.file ? newImageUrls.push(URL.createObjectURL(image.file)) : ""
    );
    setImageURLs(newImageUrls);
  }, [imageData]);

  const handleImageChange = (e) => {
    const files = e.target.files;

    // Update newImage state with the selected file

    setNewImage({
      file: files[0],
      description: newImage.description,
    });
  };

  const handleDescriptionChange = (e) => {
    // Update newImage state with the entered description
    setNewImage({
      file: newImage.file,
      description: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newImage.file) {
      toast.info("Image is required!");
      return;
    }
    if (!newImage.description) {
      toast.info("Color is required!");
      return;
    }
    let imgData = [...imageData, newImage];
    console.log("imgData", imgData);
    console.log("newImage", newImage);

    // Add the newImage to the imageData array
    setImageData([...imageData, newImage]);

    // Clear the newImage state after submission
    setNewImage({
      file: null,
      description: "",
    });
  };

  const handleSubmitf = async (e) => {
    e.preventDefault();
    console.log("imgData", imageData.length);
    if(imageData.length<=0){
      toast.info("Add atleast one product image and color!");
      return;
    }
    const data = new FormData();

    let img = imageData?.map((item) => {
      data.append("image", item.file);
      // console.log('item', item.file)
    });

    let color = imageData?.map((item) => {
      return item.description;
    });

    // data.append('image', newImage.file);
    data.append("msp", newImage.description);
    data.append("colors", color);

    navigate("addProduct", { state: imageData });
  };

  const children = imageData.concat(imageURLS);

  console.log("newImage.description", imageData);
  return (
    <>
      <div className="Row space-between">
        <h2>Upload image for add product</h2>
        <button onClick={() => navigate(-1)}>Back to product list</button>
      </div>
      <form>
        <div>
          <label htmlFor="imageInput">Select Image:</label>
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>

        {/* {newImage.file && (
        <div>
          <h4>Selected Image:</h4>
          <p>{newImage.file.name}</p>
        </div>
      )} */}

        <div>
          <label htmlFor="descriptionInput">color:</label>
          <input
            type="text"
            id="descriptionInput"
            value={newImage.description}
            onChange={handleDescriptionChange}
          />
        </div>
        <div className="Row ">
          {imageData.length > 0 ? (
            <button onClick={handleSubmit}>Add More Image & Color</button>
          ) : (
            <button onClick={handleSubmit}>Add Image & Color</button>
          )}
          <button onClick={handleSubmitf}>Next</button>
        </div>

        {imageData.length > 0 && (
          <div>
            <h4>Selected Images:</h4>
            <div className="Row space-between margin50">
              <div>
                {children.map((image, index) => (
                  <div key={index} style={{ width: "200px", height: "200px" }}>
                    <p>{image.file?.name}</p>
                    <p>{image.description}</p>
                  </div>
                ))}
              </div>

              <div>
                {imageURLS.map((imageSrc) => (
                  <img
                    src={imageSrc}
                    alt="not fount"
                    width={"70%"}
                    height={"200px"}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default ImageUploadForm;

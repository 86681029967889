
// App.js
import React, { useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useOutlet
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import Navigation from './routing/Navigation';
import Sidebar from './Sidebar';
import ProductList from './container/product/ProductList';
import Category from './container/category/List';
import CategoryUpdate from './container/category/CategoryUpdate';
import CategoryAdd from './container/category/Add';
import CartList from './container/cart/List';
import OrderList from './container/orders/List';
import SellersList from './container/Sellers/List';
import UsersList from './container/Users/List';
import Add from './container/product/Add';
import Update from './container/product/UpdateProduct';
import ProductAdd from './container/product/AddProduct';
import Dashboard from './container/dashboard/Dashboard';
import Login from './container/login/Login';
import { ProtectedLayout } from "./component/ProtectedLayout";
import { HomeLayout } from "./component/HomeLayout";
import { ProtectedSellerLayout } from "./component/ProtectedSellerLayout";
import { Layout } from "./component/Layout";


import AddBanner from './container/banner/Add';
import Banner from './container/banner/List';
import UpdateBanner from './container/banner/Update';
import UpdateUser from './container/Users/Update';
import UpdateSeller from './container/Sellers/Update';
import AddUser from './container/Users/Add';
import AddSeller from './container/Sellers/Add';
import Coupon from './container/coupon/List';
import UpdateCoupon from './container/coupon/Update';
import AddCoupon from './container/coupon/Add';
import Profile from './container/profile/User';
import ForgotPassword from './container/profile/ForgotPassword';
import CreateSellerAccount from './container/profile/CreateSellerAccount';
import AddAddress from './container/profile/AddAddress';
import SellersAddress from './container/profile/AddressList';
import UpdateAddress from './container/profile/UpdateAddress';
import Support from './container/Support/Support';
import './App.css';
const App = () => {
  const outlet = useOutlet();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const AuthWrapper = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = useState(true);

    // Check the user's authentication status or perform any other authentication logic
    console.log('isAuthenticated', isAuthenticated)
    return isAuthenticated ? { outlet } : <Navigate to="/login" />;
  };


  return (
    <>

      {/* <button title='open me' onClick={toggleSidebar}>Open me</button>*/}
      <div >
      <ToastContainer />
        <Routes>

          <Route element={<HomeLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="createAccount" element={<CreateSellerAccount />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route path="/dashboard" element={<ProtectedLayout allowedRoles={["admin"]} />}>
            <Route path="" element={<Dashboard />} />
            <Route path="product" element={<ProductList /> } />
            <Route path="product/add" element={<Add />} />
            <Route path="product/update" element={<Update />} />
            <Route path="product/add/addProduct" element={<ProductAdd />} />

            <Route path="category" element={<Category />} />
            <Route path="category/add" element={<CategoryAdd />} />
            <Route path="category/update" element={<CategoryUpdate />} />
            
            <Route path="cart" element={<CartList />} />
            <Route path="order" element={<OrderList />} />
            <Route path="sellers" element={<SellersList />} />
            <Route path="sellers/add" element={<AddSeller />} />
            <Route path="sellers/update" element={<UpdateSeller />} />
            <Route path="users" element={<UsersList />} />
            <Route path="users/add" element={<AddUser />} />
            <Route path="users/update" element={<UpdateUser />} />

            <Route path="banner" element={<Banner />} />
            <Route path="banner/add" element={<AddBanner />} />
            <Route path="banner/update" element={<UpdateBanner />} />

            <Route path="coupon" element={<Coupon />} />
            <Route path="coupon/add" element={<AddCoupon />} />
            <Route path="coupon/update" element={<UpdateCoupon />} />
            </Route>

            <Route path="/seller" element={<ProtectedLayout allowedRoles={["seller","admin"]} />}>
            <Route path="" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
            <Route path="AddAddress" element={<AddAddress />} />
            <Route path="addressList" element={<SellersAddress />} />
            <Route path="updateAddress" element={<UpdateAddress />} />


            <Route path="product" element={<ProductList />} />
            <Route path="product/add" element={<Add />} />
            <Route path="product/update" element={<Update />} />
            <Route path="product/add/addProduct" element={<ProductAdd />} />
            <Route path="support" element={<Support />} />
           
            </Route>

          </Route>

          {/* <Route path="/sellerDashboard" element={<ProtectedSellerLayout />}>
          <Route path="" element={<Dashboard />} />
            <Route path="product" element={<ProductList />} />
            <Route path="product/add" element={<Add />} />
            <Route path="product/update" element={<Update />} />
          </Route> */}

          {/* <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="product" element={<ProductList />} />
            <Route path="product/add" element={<Add />} />
            <Route path="product/update" element={<Update />} />
            <Route path="product/add/addProduct" element={<ProductAdd />} />
            <Route path="category" element={<Category />} />
            <Route path="category/add" element={<CategoryAdd />} />
            <Route path="category/update" element={<CategoryUpdate />} />
            
            <Route path="cart" element={<CartList />} />
            <Route path="order" element={<OrderList />} />
            <Route path="sellers" element={<SellersList />} />
            <Route path="sellers/add" element={<AddSeller />} />
            <Route path="sellers/update" element={<UpdateSeller />} />
            <Route path="users" element={<UsersList />} />
            <Route path="users/add" element={<AddUser />} />
            <Route path="users/update" element={<UpdateUser />} />

            <Route path="banner" element={<Banner />} />
            <Route path="banner/add" element={<AddBanner />} />
            <Route path="banner/update" element={<UpdateBanner />} />

            <Route path="coupon" element={<Coupon />} />
            <Route path="coupon/add" element={<AddCoupon />} />
            <Route path="coupon/update" element={<UpdateCoupon />} />
          </Route> */}

        </Routes>
      </div>
    </>


  );
};

export default App;

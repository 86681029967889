



let a={
   baseUrl:"https://app-hvpdly3esq-uc.a.run.app/v1/api/",
   imageUrl:"https://app-hvpdly3esq-uc.a.run.app/Images/"
    //  baseUrl:"http://127.0.0.1:5001/nodeproj-1533800975811/us-central1/app/v1/api/",
    // imageUrl:"http://127.0.0.1:5001/nodeproj-1533800975811/us-central1/app/Images/"


}

export default a
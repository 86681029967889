import React, { useState, useEffect } from "react";
import Constant from "../../constant/Constant";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../component/Table";
import { Loader } from "../../component/Loader";

const Coupon = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Constant.baseUrl}coupon`);
        const result = await response.json();
        setData(result.result);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once when the component mounts

  return (
    <div>
      <Loader loading={isLoading} />
      <div className="Row space-between">
        <h2>Coupon</h2>
        <button onClick={() => navigate("/dashboard/coupon/add")}>
          Add coupon
        </button>
      </div>

      <table className="styled-table">
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Code</th>
            <th>Discount Amount</th>
            <th>description</th>
            <th>Valid From</th>
            <th>Valid To</th>
            <th>Is Active</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={row._id}>
              <td>{index + 1}</td>

              <td>{row.code}</td>
              <td>{row.discountAmount}</td>
              <td>{row.description}</td>
              <td>{row.validFrom}</td>
              <td>{row.validTo}</td>
              <td>{row.isActive ? "Yes" : "No"}</td>
              <td>
                <div className="Column">
                  <button onClick={() => navigate("update", { state: row })}>
                    Update
                  </button>
                  <button>Remove</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Coupon;




// Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "./hooks/useAuth";

import './Sidebar.css';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { user, logout } = useAuth();

  const linkStyle = {
    margin: "1rem",
    textDecoration: "none",
    color: 'blue',
    color: '#FFFF'
  };
  return (
    <div className={`sidebar ${isOpen ? 'sidebar-open' : ''}`}>

      {/* <nav> */}
      {user?.role == 'admin' ?
        <ul>
          <li>
            <Link to="/" style={linkStyle}>Home</Link>
          </li>
          <li>
            <Link to="/dashboard/category" style={linkStyle}>Category</Link>
          </li>
          <li>
            <Link to="/dashboard/product" style={linkStyle}>Product</Link>
          </li>
          <li>
            <Link to="/dashboard/cart" style={linkStyle}>Cart</Link>
          </li>
          <li>
            <Link to="/dashboard/coupon" style={linkStyle}>Coupon</Link>
          </li>
          <li>
            <Link to="/dashboard/users" style={linkStyle}>User</Link>
          </li>
          <li>
            <Link to="/dashboard/order" style={linkStyle}>Orders</Link>
          </li>

          <li>
            <Link to="/dashboard/sellers" style={linkStyle}>Sellar</Link>
          </li>
          <li>
            <Link to="/dashboard/banner" style={linkStyle}>Banner</Link>
          </li>
          <li>
            <Link to="/dashboard/userAddress" style={linkStyle}>User Address</Link>
          </li>
          <li>
            <Link to="/dashboard/sellerAddress" style={linkStyle}>Sellar Address</Link>
          </li>
          <li>
            <Link to="/dashboard/support" style={linkStyle}>Support</Link>
          </li>
        </ul> :
        <ul>
          <li>
            <Link to="/seller/profile" style={linkStyle}>Profile</Link>
          </li>
          <li>
            <Link to="/seller/addressList" style={linkStyle}>Address</Link>
          </li>
          {/* <li>
            <Link to="/seller/AddAddress" style={linkStyle}>Address</Link>
          </li> */}

          <li>
            <Link to="/seller/product" style={linkStyle}>Product</Link>
          </li>
          <li>
            <Link to="/seller/support" style={linkStyle}>Support</Link>
          </li>
        </ul>
      }

      {/* <button className="toggle-btn" onClick={toggleSidebar}>
        Closed
      </button> */}
      <button className="button toggle-btn" onClick={toggleSidebar} style={{
        textAlign: 'center', left: '247px', top: '96px', width: '66px'

      }}>Close</button>



      {/* {!!user && (
        <button
          key={"logout"}
          onClick={logout}
        // sx={{ my: 2, color: "white", display: "block" }}
        >
          Logout
        </button>
      )} */}

    </div>
  );
};

export default Sidebar;

// AddBanner.js
import React, { useState } from 'react';
import {useLocation} from 'react-router-dom';
import Constant from '../../constant/Constant';
import { useNavigate } from "react-router-dom";

const AddBanner = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);


  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'image') {
      const selectedImage = files[0];
      setFormData({ ...formData, [name]: selectedImage });

      // Display the selected image as a preview
      if (selectedImage) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      } else {
        setPreviewImage(null);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('filename', formData.image);
    data.append('description', formData.description);
    data.append('scientific_name', formData.scientific_name);
    data.append('name', formData.name);

    // Send the FormData to your server for image upload
    try {
      const response = await fetch(`${Constant.baseUrl}addCategorie`, {
        method: 'POST',
        body: data,
      });

      if (response.ok) {
        console.log('Image uploaded successfully.');
        navigate("/category")
      } else {
        console.error('Image upload failed.');
        // Handle failure, such as displaying an error message
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  return (

    <>
           <div className="Row space-between">
        <h2>Add Banner</h2>
        <button onClick={() => navigate(-1)}>Add Banner</button>
                  
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
       <label htmlFor="image">Choose Image:</label>
        <input type="file" id="image" name="image" onChange={handleChange} />

        {previewImage && (
        <div className="image-preview">
          <p>Selected Image Preview:</p>
          <img src={previewImage} alt="Preview" width={'100px'} height={'100px'}/>
        </div>
      )}

      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />

      <label htmlFor="scientific_name">Scientific Name:</label>
      <input
        type="text"
        id="scientific_name"
        name="scientific_name"
        value={formData.scientific_name}
        onChange={handleChange}
      />

      <label htmlFor="description">Description:</label>
      <textarea
      rows="10"
        id="description"
        name="description"
        value={formData.description}
        onChange={handleChange}
      ></textarea>

      <button type="submit">Submit</button>
    </form></>

  );
};

export default AddBanner;

// import React, { useState } from 'react';

// import {  Navigate, useOutlet } from "react-router-dom";
// import { useAuth } from "../hooks/useAuth";
// import Sidebar from "../Sidebar";
// export const ProtectedLayout = () => {
//   const { user,logout } = useAuth();
//   const outlet = useOutlet();

//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };
//   // console.log(window.innerWidth,'window.innerWidth;')


//   if (!user) {
//     return <Navigate to="/" />;
//   }

//   console.log('outlet',outlet)
//   return (
//     <>
//       <header className="dashboard-header">
//         <h1></h1>
//         <button  onClick={logout} className="logout-button">Logout</button>
//       </header>
//       <div className={`content`} style={{ marginLeft: isSidebarOpen ? '256px' : '12px' }}>
//         {!isSidebarOpen&&<button className="button" onClick={toggleSidebar} style={{
//           textAlign: 'center', left: '216px', top: '35px',
//         }}>{'Open'}</button>}
//         <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
//         {outlet}
//       </div>
//     </>

//   );
// };


import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { useAuth } from "../hooks/useAuth";
export const ProtectedLayout = ({ allowedRoles }) => {
  const { user,logout } = useAuth();
  // const { auth } = {auth:'seller'};
  const location = useLocation();


  // console.log('user roloe from database',user.role)
  // console.log('allowedRoles',allowedRoles)

  // allowedRoles.find((role) => {
  //   console.log('user?.role',user?.role)

  //   console.log('role',role)

  //   console.log('auth===>',user?.role.includes(role))

  // })



  return allowedRoles.find((role) => user?.role.includes(role)) ? (
    <Outlet />
  ) : user?.name ? (
    <Navigate to="/product" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

// export const ProtectedLayout;


import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Constant from "../../constant/Constant";
import { Loader } from "../../component/Loader";
import "./ForgotPassword.css"; // Import the CSS file

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [userID, setUserID] = useState("");
  const [otp, setOtp] = useState("");

  const [otpField, setOtpField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [password, setPassword] = useState({});


  const handleVerifyUser = async (updatedUser) => {
    if (!userID) {
      toast.info("User required!");
      return;
    }
    setIsLoading(true)
    let data = {
      userID: userID,
    };
    try {
      const response = await fetch(`${Constant.baseUrl}verifyUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const userRes = await response.json();
      console.log(userRes.code);
      if (response.ok) {
        setResponse(userRes.result);
        setOtpField(true);
        toast.success("OTP Sent!");
        setIsLoading(false);
      } else {
        toast.info(userRes.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Somthing went wrong!");
      setIsLoading(false);
      console.error("Error uploading image:", error);
    }
  };

  const handleVerifyOtp = async () => {

    if (!otp) {
      toast.info("OTP required!");
      return;
    }
    if (password.newPassword !== password.confirmPassword) {
      toast.info("New Password and confirm password not matching!");
      return;
    }
    setIsLoading(true)
    let data = {
      id: response._id,
      otp: otp,
      password:password.confirmPassword
    };
    console.log("response data", data);

    try {
      const response = await fetch(`${Constant.baseUrl}verifyOtp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const userRes = await response.json();
      console.log(userRes.code);
      if (response.ok) {
        setResponse(userRes.result);
        setOtpField(true);
        toast.success("Password change sucessfully!");
        setIsLoading(false);
        navigate("/login")
      } else {
        toast.info(userRes.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Somthing went wrong!");
      setIsLoading(false);
      console.error("Error verify otp:", error);
    }
  };

  return (
    <div className="forgot-password-container">
        <Loader loading={isLoading} />
      <h2>Forgot Password</h2>
      <>
        {!otpField && (
          <>
            <label>{`User ID (Phone or Email):`}</label>
            <input
              type="text"
              value={userID}
              onChange={(e) => setUserID(e.target.value)}
            />
            <button type="button" onClick={handleVerifyUser}>
              Reset Password
            </button>
          </>
        )}
        <br />
        {otpField && (
          <>
            <label>{`Enter OTP`}</label>
            <input
              required
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />

            <label>{`New password`}</label>
            <input
              required
              type="text"
              value={password.newPassword}
              onChange={(e) =>
                setPassword({ ...password, newPassword: e.target.value.trim() })
              }
            />

            <label>{`Confirm Password`}</label>
            <input
              required
              type="text"
              value={password.confirmPassword}
              onChange={(e) =>
                setPassword({
                  ...password,
                  confirmPassword: e.target.value.trim(),
                })
              }
            />
            <button type="button" onClick={handleVerifyOtp}>
              Submit
            </button>
          </>
        )}
      </>
    </div>
  );
};

export default ForgotPassword;

import React, { useState } from 'react';

import {  Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Sidebar from "../Sidebar";
export const Layout = () => {
  const { user,logout } = useAuth();
  const outlet = useOutlet();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // console.log(window.innerWidth,'window.innerWidth;')


  // if (!user) {
  //   return <Navigate to="/" />;
  // }
  return (
    <>
      <header className="dashboard-header">
        <h1></h1>
        <button  onClick={logout} className="logout-button">Logout</button>
      </header>
      <div className={`content`} style={{ marginLeft: isSidebarOpen ? '256px' : '12px' }}>
        {!isSidebarOpen&&<button className="button" onClick={toggleSidebar} style={{
          textAlign: 'center', left: '216px', top: '35px',
        }}>{'Open'}</button>}
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        {outlet}
      </div>
    </>

  );
};


import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Constant from '../../constant/Constant';
import { useNavigate } from "react-router-dom";
import { Loader }  from '../../component/Loader';
import { ToastContainer, toast } from 'react-toastify';
import "./ForgotPassword.css"; // Import the CSS file



const CreateSellerAccount = () => {
  
  const navigate = useNavigate();

  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'image') {
      const selectedImage = files[0];
      setFormData({ ...formData, [name]: selectedImage });

      // Display the selected image as a preview
      if (selectedImage) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      } else {
        setPreviewImage(null);
      }
    } else {
      setFormData({ ...formData, [name]: value.trim() });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData();
    data.append('filename', formData.image);
    data.append('userID', formData.userID);
    data.append('email', formData.email);
    data.append('firstName', formData.firstName);
    data.append('lastName', formData.lastName);
    data.append('shopName', formData.shopName);
    data.append('phoneNo', formData.phoneNo);
    data.append('password', formData.password);
    data.append('gender', formData.gender);


    // Send the FormData to your server for image upload
    try {
      const response = await fetch(`${Constant.baseUrl}addSeller`, {
        method: 'POST',
        body: data,
      });
      console.log('response', response)
      if (response.ok) {
        toast.success("Seller Added!")
        setIsLoading(false);
        console.log('Image uploaded successfully.');
        navigate("/dashboard/sellers")
      } else {
        toast.info("Seller Not added!")
        setIsLoading(false);
        console.error('Image upload failed.');
        // Handle failure, such as displaying an error message
      }
    } catch (error) {
      toast.error("Somthing went wrong!")
      setIsLoading(false);
      console.error('Error uploading image:', error);
    }
  };

  return (
    <div className="forgot-password-container">
        <Loader loading={isLoading} />
      <h2>Create seller account</h2>
      <>
      <form className="contact-form" onSubmit={handleSubmit}>
        <label htmlFor="image">Choose Image:</label>
        <input type="file" id="image" name="image" onChange={handleChange} />

        {previewImage && (
          <div className="image-preview">
            <p>Selected Image Preview:</p>
            <img src={previewImage} alt="Preview" width={'100px'} height={'100px'} />
          </div>
        )}

        <label htmlFor="gender">Gender:</label>
        <select
          id="gender"
          name="gender"
          value={formData.gender}
          onChange={handleChange}
        >
          {/* <option value="Male" disabled>
                    Gender
                    </option> */}
          <option key='Male' value="Male">
            Male
          </option>
          <option key='Female' value="Female">
            Female
          </option>
        </select>


        <label htmlFor="userID">User Name :</label>
        <input
          type="text"
          id="userID"
          name="userID"
          value={formData.userID}
          onChange={handleChange}
        />

        <label htmlFor="password">Password:</label>
        <input
          type="text"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />

        <label htmlFor="email">Email:</label>
        <input
          type="text"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />

        <label htmlFor="phoneNo">PhoneNo:</label>
        <input
          rows="10"
          id="phoneNo"
          name="phoneNo"
          value={formData.phoneNo}
          onChange={handleChange}
        />

        <label htmlFor="firstName">First Name:</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />

        <label htmlFor="lastName">Last Name:</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />

        <label htmlFor="shopName">Shop Name:</label>
        <input
          type="text"
          id="shopName"
          name="shopName"
          value={formData.shopName}
          onChange={handleChange}
        />


        <button type="submit">Submit</button>
      </form>
      </>
    </div>
  );
};

export default CreateSellerAccount;

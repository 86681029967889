
import React, { useState, useEffect } from 'react';
import Constant from '../../constant/Constant';
import { useNavigate } from "react-router-dom";
import TableComponent from '../../component/Table';
import { Loader }  from '../../component/Loader';


const Users = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Constant.baseUrl}users`);
        const result = await response.json();
        const cartResponse = await fetch(`${Constant.baseUrl}carts`);
        const cartResult = await cartResponse.json();
        console.log('cartResult',cartResult.result?.length)
        setCartCount(cartResult.result?.length)
        setImageUrl(result.imageURL)
        setData(result.result);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once when the component mounts

  return (
    <div>
      <Loader loading={isLoading} />
       <div className="Row space-between">
        <h2>Users</h2>
        <button onClick={() => navigate("add")}>Add User</button>
                  
        </div>
     
        <table className="styled-table">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Image</th>
              <th>User Id</th>
              <th> Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Shop Name</th>
              <th>Gender</th>
              <th>Cart</th>
              <th>Orders</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={row._id}>
                <td>{index + 1}</td>
                <td>
                 {imageUrl?
                 <img src={imageUrl + row.image} alt="catImage" className='img' />:
                 <img src={Constant.imageUrl + row.image} alt="catImage" className='img' />
                 }
                </td>

                <td>{row.userID}</td>
                <td>{row.email}</td>
                <td>{row.firstName}</td>
                <td>{row.lastName}</td>
                <td>{row.shopName}</td>
                <td>{row.gender}</td>
                <td>{cartCount}
                <a href="/cart"> View cart</a>

                </td>
                <td>{cartCount}
                <a href="/cart"> View orders</a>

                </td>

                <td >
                  <div className="Row">
                    <button onClick={() => navigate("update", { state: row })}>Update</button>
                    <button>Remove</button>
                  </div>

                </td>


              </tr>
            ))}
          </tbody>
        </table>
    </div>
  );
};

export default Users;


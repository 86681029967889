
import React, { useState, useEffect } from 'react';
import Constant from '../../constant/Constant';
import { useNavigate } from "react-router-dom";
import TableComponent from '../../component/Table';
import { Loader }  from '../../component/Loader';

const Banner = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await fetch(`${Constant.baseUrl}categories`);
        const result = await response.json();
        setImageUrl(result.imageURL)
        setData(result.result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once when the component mounts

  return (
    <div>
      <Loader loading={loading}/>
       <div className="Row space-between">
        <h2>Banner</h2>
        <button onClick={() => navigate("add")}>Add Banner</button>
                  
        </div>

        <table className="styled-table">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Image</th>
              <th>Name</th>
              <th>Scientific name</th>
              <th>description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={row._id}>
                <td>{index + 1}</td>
                <td>
                  <img src={imageUrl + row.image} alt="catImage" className='img' />
                </td>

                <td>{row.name}</td>
                <td>{row.scientific_name}</td>
                <td>{row.description}</td>
                <td >
                  <div className="Column">
                    <button onClick={() => navigate("update", { state: row })}>Update</button>
                    <button>Remove</button>
                  </div>

                </td>


              </tr>
            ))}
          </tbody>
        </table>
     
      {/* {isLoading ? (
        <p>Loading...</p>
      ) : (

        <table className="styled-table">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Image</th>
              <th>Name</th>
              <th>Scientific name</th>
              <th>description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={row._id}>
                <td>{index + 1}</td>
                <td>
                  <img src={imageUrl + row.image} alt="catImage" className='img' />
                </td>

                <td>{row.name}</td>
                <td>{row.scientific_name}</td>
                <td>{row.description}</td>
                <td >
                  <div className="Column">
                    <button onClick={() => navigate("update", { state: row })}>Update</button>
                    <button>Remove</button>
                  </div>

                </td>


              </tr>
            ))}
          </tbody>
        </table>
      )} */}
    </div>
  );
};

export default Banner;


import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Loader } from "./Loader";
export const HomeLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  if (user?.role == "seller") {
    return <Navigate to="/seller" replace />;
  } else if (user?.role == "admin") {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="container">
      <div className="child">
        {/* <Loader loading={false}/> */}
        {outlet}
      </div>
    </div>
  );
};


import React, { useState, useEffect } from 'react';
import Constant from '../../constant/Constant';
import { useNavigate } from "react-router-dom";
import TableComponent from '../../component/Table';
import { Loader } from '../../component/Loader';

const List = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Constant.baseUrl}orders`);
        const result = await response.json();
        setImageUrl(result.imageURL)
        setData(result.result);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once when the component mounts

  return (
    <div>
      <Loader loading={isLoading} />
       <div className="Row space-between">
        <h2>List</h2>
        <button onClick={() => navigate("/category/add")}>Add category</button>
                  
        </div>
    </div>
  );
};

export default List;


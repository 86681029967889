import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Constant from "../../constant/Constant";
import { Loader } from "../../component/Loader";
import { useAuth } from "../../hooks/useAuth";

import "./ForgotPassword.css"; // Import the CSS file

const AddAddress = () => {
    const { user, login } = useAuth();

  const navigate = useNavigate();
  const [userID, setUserID] = useState("");
  const [otp, setOtp] = useState("");

  const [otpField, setOtpField] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState({});
  const [password, setPassword] = useState({});
  const [formData, setFormData] = useState({});

  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("position.coords", position.coords);
          fetchData(latitude, longitude);
          setLocation({ latitude, longitude });
        },
        (error) => {
          setError(`Error getting location: ${error.message}`);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser");
    }
  }, []);

  const fetchData = async (latitude, longitude) => {
    try {
      let payload = {
        latitude,
        longitude,
      };
      const response = await fetch(`${Constant.baseUrl}getReverseLocation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const locationRes = await response.json();
      if (response.ok) {
        let userInfo={
            fullName: user.firstName +' '+ user.lastName||'',
            phoneNo: user.phoneNo||'',
            ...locationRes.result
        }
        console.log('userInfo',userInfo)
        setFormData(userInfo);
        setIsLoading(false);
      } else {
        toast.info(locationRes.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveAddress = async () => {
    formData.phoneNo=user.phoneNo;
    formData.email=user.email;
    formData.uuid=user._id
    console.log('user',user.phoneNo)

    setIsLoading(true);

    console.log("formData data", formData);

    try {
      const response = await fetch(`${Constant.baseUrl}addSellerAddress`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const userRes = await response.json();
      console.log(userRes.code);
      if (response.ok) {
        setOtpField(true);
        toast.success("Address add sucessfully!");
        setIsLoading(false);
        navigate(-1)
      } else {
        toast.info(userRes.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Somthing went wrong!");
      setIsLoading(false);
      console.error("Error verify otp:", error);
    }
  };

  return (
    <div className="forgot-password-container">
      <Loader loading={isLoading} />
      <h2>Add Address from your shop location only </h2>
      <>
        <>
          <label>{`Name`}</label>
          <input
            required
            type="text"
            name="name"
            value={formData.fullName}
            onChange={(e) => handleInputChange(e)}
          />
          <label>{`Phone no`}</label>
          <input
            required
            type="text"
            name="phoneNo"
            value={formData.phoneNo}
            onChange={(e) => handleInputChange(e)}
          />
          <label>{`Street`}</label>
          <input
            required
            type="text"
            name="street"
            value={formData.street}
            onChange={(e) => handleInputChange(e)}
          />
          <label>{`House no buildingName`}</label>
          <input
            required
            type="text"
            name="houseNoBuildingName"
            value={formData.houseNoBuildingName}
            onChange={(e) => handleInputChange(e)}
          />
          <label>{`Area road name colony name`}</label>
          <input
            required
            type="text"
            name="areaRoadNameColonyName"
            value={formData.areaRoadNameColonyName}
            onChange={(e) => handleInputChange(e)}
          />
          <label>{`City`}</label>
          <input
            required
            type="text"
            name="city"
            value={formData.city}
            onChange={(e) => handleInputChange(e)}
          />
          <label>{`State`}</label>
          <input
            required
            type="text"
            name="state"
            value={formData.state}
            onChange={(e) => handleInputChange(e)}
          />
          <label>{`Zip code (Area pin Code)`}</label>
          <input
            required
            type="text"
            name="zipCode"
            value={formData.zipCode}
            onChange={(e) => handleInputChange(e)}
          />
          <label>{`Full Address`}</label>
          <textarea
            required
            type="text"
            name="fullAddress"
            value={formData.fullAddress}
            onChange={(e) => handleInputChange(e)}
          />
          <button type="button" onClick={handleSaveAddress}>
            Submit
          </button>
        </>
      </>
    </div>
  );
};

export default AddAddress;
